import React from 'react';
import { ROOT_IMG_URL } from '../../../../GlobalValues';
import './AppLinks.css';

const AppLinks = () => {
  return (
    <div className="appLinksWrapper">
      <a
        rel="nofollow noopener noreferrer"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.hairnet.fileattente"
      >
        <img
          src={ROOT_IMG_URL + 'GooglePlayBadge.svg'}
          alt="télécharger l application sur le google playstore"
          width="100px"
          loading="lazy"
        ></img>
      </a>
      <a
        href="https://apps.apple.com/us/app/tchip-moi/id1554437088"
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        <img
          loading='lazy'
          width="88px"
          src={ROOT_IMG_URL + 'download-appstore.svg'}
          alt="télécharger l application sur le google playstore"
        ></img>
      </a>
    </div>
  );
};

export default AppLinks;
