import React, { useState, useContext, useEffect } from 'react';
import MapContext from 'common/contexts/map-context';
import MapService from 'common/services/Map';
import TrackingService from 'common/services/Tracking/Tracking';
import ExtractorService from 'common/services/Extractor';
import BasicButton from 'common/components/Widgets/BasicButton/BasicButton';
import RichFieldGroup from 'common/components/RichFieldGroup/RichFieldGroup';

import { AsYouType } from 'libphonenumber-js';
import './PlaceInfos.css';
import {
  RICHFIELDS_CONFIG_DESKTOP,
  RICHFIELDS_CONFIG_MOBILE,
} from '../../richfieldsConfig/richfieldsConfig';

const PlaceInfosButtons = ({ merchant, isMobile }) => {
  const [map] = useContext(MapContext);
  const [displayPhone, setDisplayPhone] = useState('Afficher le numéro');

  const getItinaryLink = async (e) => {
    e.preventDefault();
    if (map.scriptInited) {
      const { pagination } = await MapService.goToGeolocation(false);
      const infos = await MapService.getItinaryURL(merchant, {
        lat: pagination.from_lat,
        lng: pagination.from_lng,
      });
      TrackingService.trackClickToDirection(merchant.id, 'real');
      if (!window.open(infos.url, infos.target)) {
        window.location = infos.url;
      }
    }
  };

  const seePhone = () => {
    if (!merchant.phone) {
      return;
    }
    const phoneNumber = new AsYouType().input(merchant.phone);
    TrackingService.trackClickToCall(merchant.id, 'real');
    setDisplayPhone(phoneNumber);
  };

  useEffect(() => {
    if (isMobile && displayPhone === 'Afficher le numéro') {
      setDisplayPhone('Appeler');
    }
  }, [isMobile, displayPhone]);

  return (
    <div className="flexGap placeInfosButtonsWrapper">
      {merchant.phone && (
        <BasicButton
          label={displayPhone}
          isBig={true}
          onClick={() => seePhone()}
          hover={'var(--secondary-color)'}
          href={'tel:' + merchant.phone}
          icon={'faPhone'}
          iconStyle={{
            fontSize: 'var(--fontsize-m)',
          }}
          type={'phoneButton'}
        />
      )}

      <BasicButton
        label="Calculer mon itinéraire"
        isBig={true}
        icon={'faMapLocationDot'}
        hover={'var(--secondary-color)'}
        onClick={(e) => getItinaryLink(e)}
        iconStyle={{
          fontSize: 'var(--fontsize-m)',
        }}
        type={'itinaryButton'}
      />
    </div>
  );
};

const PlaceInfos = ({ merchant, isMobile }) => {
  const critizrField = merchant.extended_data.extended_string_data_8;
  let critizrEnable = false;

  if (critizrField) {
    critizrEnable = ExtractorService.extractBoolean(critizrField);
  }

  return (
    <div className="placeInfosContainer">
      <section className="placeInfosWrapper">
        <div className="placeInfoNoCrit">
          <div className="flexGapColumn">
            <img
              src="https://placeloop-media.s3.amazonaws.com/brands/127243036029/contentportal/items/41245076019796--orig-preview"
              alt="logo TCHIP"
              loading="lazy"
              height="100"
              style={{ height: '100px' }}
            />
          </div>

          <div className="placeInfosDatas">
            <h1
              style={
                critizrEnable
                  ? {
                      maxWidth: '430px',
                    }
                  : {}
              }
            >
              {merchant.name}
            </h1>
            <div className="flexGap placeInfosMiddle">
              <address
                className="placeInfosAddress"
                style={
                  critizrEnable
                    ? {
                        maxWidth: '430px',
                      }
                    : {}
                }
              >
                {merchant.address && (
                  <>
                    <p>{merchant.address.street}</p>
                    <div className="flexGap">
                      <p>{merchant.address.zip}</p>
                      <p>{merchant.address.city}</p>
                    </div>
                  </>
                )}
              </address>
            </div>
          </div>
        </div>
        {critizrEnable && (
          <div className="placeInfosCritizrWrapper">
            <div className="placeInfosCritizr" style={{ height: '115px' }}>
              <a
                className="critizr-widget"
                href={`https://critizr.com/widgets/vog-tchip/${merchant.reference}/`}
                data-type="store_button:rating_satisfaction"
              >
                Critizr: Envoyer mon avis
              </a>
            </div>
            <div
              style={{
                borderRadius: 'var(--borderRadius-m)',
                border: '3px solid white',
                marginTop: 'var(--gutter-s)',
              }}
            >
              <a
                class="goodays-widget"
                href={`https://app.goodays.co/widgets/vog-tchip/${merchant.reference}/`}
                data-type="store_button:fact"
                data-params={`{"on_click":{"type":"cz:open:send_feedback:${merchant.reference}"},"cta_text":"Envoyer une remarque"}`}
              >
                Goodays: Envoyer mon avis
              </a>
            </div>
          </div>
        )}
      </section>
      <div className="placeInfosModular">
        <PlaceInfosButtons merchant={merchant} isMobile={isMobile} />

        <div className="placeInfosRichFields desktop-only">
          <RichFieldGroup
            groupId={'MAIN_D'}
            style={RICHFIELDS_CONFIG_DESKTOP.MAIN_D}
          ></RichFieldGroup>
        </div>
      </div>

      <div className="placeInfosRichFields mobile-only">
        <RichFieldGroup
          groupId={'MAIN_D'}
          style={RICHFIELDS_CONFIG_MOBILE.MAIN_D}
        ></RichFieldGroup>
      </div>
    </div>
  );
};

export default PlaceInfos;
