import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="Header">
      <input className="hidden" type="checkbox" id="toggle-menu"></input>
      <header className="header">
        <div className="wrapper">
          <div className="grid">
            <h1 className="header__logo">
              <a href="https://tchip.fr/">
                <img
                  loading='lazy'
                  className="logo"
                  src="https://tchip.fr/Content/images/logo.png"
                  width="54"
                  height="55"
                  alt="Tchip Coiffure"
                  title="Tchip Coiffure"
                />
              </a>
              <img
                loading='lazy'
                className="baseline"
                src="https://tchip.fr/Content/images/baseline.svg"
                width="122"
                height="9"
                alt="La coiffure à petits prix"
                title="La coiffure à petits prix"
              />
            </h1>

            <form
              action="https://tchip.fr/search"
              className="header__search"
              method="get"
              onSubmit="return check_small_search_form()"
            >
              {' '}
              <label
                className="header__search__label icon-search hide-md"
                htmlFor="h-search"
                style={{ visibility: 'hidden' }}
              ></label>
              <div
                className="header__search__field-container"
                style={{ visibility: 'hidden' }}
              >
                <div className="field">
                  <input
                    className="field__input field__input--placeholder"
                    type="search"
                    autoComplete="off"
                    value=""
                    name="q"
                    id="h-search"
                    placeholder="Recherche"
                  />
                  <button
                    className="field__inner-btn icon-next"
                    type="submit"
                    title="OK"
                  ></button>
                </div>
              </div>
              {/* <script type="text/javascript">
                        function check_small_search_form() {
                            var searchTerms = $('#h-search');
                            if (searchTerms.val() === '' || searchTerms.val() === 'Rechercher...') {
                                alert('Entrez les mots clés à rechercher');
                                searchTerms.focus();
                                return false;
                            }
                            return true;
                        }
                    </script> */}
            </form>

            <div className="header__buttons show-l">
              {/* <a
                className="newsletter icon-newsletter"
                href="#footer-newsletter"
                title="S'inscrire à la newsletter"
              >
                Newsletter
              </a> */}
              <a className="account icon-account" href="https://tchip.fr/login">
                Tchip &amp; moi
              </a>
            </div>
            <label className="header__burger hide-l" htmlFor="toggle-menu">
              <span></span>
            </label>
          </div>

          <nav
            className="header__nav"
            role="navigation"
            aria-label="Main navigation"
          >
            <ul>
              <li className="header__nav__item header__nav__item--has-subnav">
                <span>Notre marque</span>

                <ul className="subnav">
                  <li className="subnav__item">
                    <a href="https://www.tchip.fr/marquehistoiretchip">
                      Notre histoire
                    </a>
                  </li>
                  <li className="subnav__item">
                    <a href="https://www.tchip.fr/marqueexpertisetchip">
                      Nos engagements
                    </a>
                  </li>
                </ul>
              </li>
              <li className="header__nav__item header__nav__item--has-subnav">
                <span>Nos services</span>

                <ul className="subnav">
                  <li className="subnav__item">
                    <a href="https://www.tchip.fr/services_forfaits">
                      Nos forfaits
                    </a>
                  </li>
                  <li className="subnav__item">
                    <a href="https://www.tchip.fr/services_couleurs">
                      Nos couleurs
                    </a>
                  </li>
                  <li className="subnav__item">
                    <a href="https://www.tchip.fr/services_soins">Nos soins</a>
                  </li>
                </ul>
              </li>
              <li className="header__nav__item">
                <a href="https://www.tchip.fr/our_products">Nos produits</a>
              </li>
              <li className="header__nav__item">
                <a href="/">Nos salons</a>
              </li>
              <li className="header__nav__item">
                <a href="https://www.tchip.fr/nos_collections">
                  Nos collections
                </a>
              </li>
              <li className="header__nav__item header__nav__item--has-subnav">
                <span>Nos astuces d&#39;experts</span>

                <ul className="subnav">
                  <li className="subnav__item">
                    <a href="https://www.tchip.fr/nos-conseils-cheveux">
                      Nos conseils cheveux
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
