import React, { useEffect, useRef } from 'react';
import './Footer.css';

const FooterTextLinks = () => {
  const footerNav = useRef();

  useEffect(() => {
    if (footerNav.current) {
      footerNav.current.style.columns = 2;
    }
  }, []);

  return (
    <ul class="footer__nav">
      <li class="footer__nav__item">
        <span>Notre marque</span>

        <ul class="subnav">
          <li class="subnav__item">
            <a href="https://www.tchip.fr/marquehistoiretchip">
              Notre histoire
            </a>
          </li>
          <li class="subnav__item">
            <a href="https://www.tchip.fr/marqueexpertisetchip">
              Nos engagements
            </a>
          </li>
        </ul>
      </li>
      <li class="footer__nav__item">
        <span>Nos services</span>

        <ul class="subnav">
          <li class="subnav__item">
            <a href="https://www.tchip.fr/services_forfaits">Nos forfaits</a>
          </li>
          <li class="subnav__item">
            <a href="https://www.tchip.fr/services_couleurs">Nos couleurs</a>
          </li>
          <li class="subnav__item">
            <a href="https://www.tchip.fr/services_soins">Nos soins</a>
          </li>
        </ul>
      </li>
      <li class="footer__nav__item">
        <a href="https://www.tchip.fr/our_products">Nos produits</a>
      </li>
      <li class="footer__nav__item">
        <a href="/">Nos salons</a>
      </li>
      <li class="footer__nav__item">
        <a href="https://www.tchip.fr/nos_collections">Nos collections</a>
      </li>
      <li class="footer__nav__item">
        <span>Nos astuces d&#39;experts</span>

        <ul class="subnav">
          <li class="subnav__item">
            <a href="https://www.tchip.fr/nos-conseils-cheveux">
              Nos conseils cheveux
            </a>
          </li>
        </ul>
      </li>
      <li class="footer__nav__item">
        <a
          href="#account"
          onClick="location.href = $('.header__buttons  a.account').attr('href')"
        >
          Tchip &amp; moi
        </a>
      </li>
      <li class="footer__nav__item">
        <a href="https://tchip.fr/nous-rejoindre" title="Rejoignez-nous">
          Rejoignez-nous
        </a>
      </li>

      <li class="footer__nav__item">
        <a
          class="critizr-widget"
          href="https://critizr.com/widgets/vog-tchip/"
          data-type="store_button:fact_national"
          data-params='{"on_click":{"type":"cz:open:store_locator","params":{"hidden":"true"}},"cta_text":"nous contacter"}'
        >
          Critizr: Envoyer mon avis
        </a>
      </li>
      <li class="footer__nav__item">
        <a
          href="https://landing.tchip.fr/rejoignez-nous/?utm_source=tchip&utm_medium=site&utm_campaign=footer"
          title="OUVREZ VOTRE SALON"
        >
          OUVREZ VOTRE SALON
        </a>
      </li>
      <li class="footer__nav__item">
        <a
          href="https://tchip.fr/mentionslegalestchip"
          title="Mentions légales"
        >
          Mentions légales
        </a>
      </li>
      <li class="footer__nav__item">
        <a
          href="https://tchip.fr/cgu-2"
          title="Conditions Générales d’Utilisation"
        >
          Conditions Générales d’Utilisation
        </a>
      </li>
    </ul>
  );
};

const Footer = () => {
  return (
    <div className="Footer">
      <footer class="footer">
        <div class="footer__nl-subscription" id="footer-newsletter"></div>

        <div class="grid">
          <a class="footer__logo" href="https://tchip.fr/" title="Accueil">
            <img
              loading='lazy'
              class="logo"
              src="https://tchip.fr/Content/images/LOGO-SMART-TCHIP-Vert.png"
              width="98"
              height="98"
              alt="Tchip Coiffure"
              title="Tchip Coiffure"
            />
          </a>

          <span className="linksAll">
            <span className="tchipLinks">
              <FooterTextLinks></FooterTextLinks>
              <div class="footer__social-networks">
                <span>Suivez-nous</span>
                {/* eslint-disable-next-line */}
                <a
                  class="icon-facebook"
                  href="https://www.facebook.com/TCHIP.Coiffure.Officiel/"
                  target="_blank"
                  rel="external noopener noreferrer"
                  title="Facebook"
                ></a>
                {/* eslint-disable-next-line */}
                <a
                  class="icon-youtube"
                  href="https://www.youtube.com/channel/UCQq6dehusft7UMyi_Y_9iXw"
                  target="_blank"
                  rel="external noopener noreferrer"
                  title="YouTube"
                ></a>
                {/* eslint-disable-next-line */}
                <a
                  class="icon-instagram"
                  href="https://www.instagram.com/tchip_coiffure/"
                  target="_blank"
                  rel="external noopener noreferrer"
                  title="Instagram"
                ></a>
              </div>
            </span>
          </span>

          <hr />
        </div>

        <div class="footer__copywrite">
          <div class="grid">
            <p>&copy; 2022 Tchip. Tous droits réservés.</p>
            <a
              href="https://tchip.fr/privacyinfotchip"
              title="Politique de Confidentialité"
            >
              Politique de Confidentialité.
            </a>
            <a
              href="https://tchip.fr/cookiedisclaimertchip"
              title="Politique de Cookie"
            >
              Politique de Cookie.
            </a>
            {/* <p class="author">
              Site réalisé par{" "}
              <a
                href="https://www.krealid.com/"
                target="_blank"
                rel="external noopener noreferrer"
                title="Kréalid"
              >
                <img
                  src="https://tchip.fr/Content/images/krealid.svg"
                  width="60"
                  height="11"
                  alt="Kréalid"
                  title="Kréalid"
                />
              </a>
            </p> */}
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
