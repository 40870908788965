import React, { createRef, useContext } from 'react';
import Map from 'common/components/Map/Map';
import MapService from 'common/services/Map';
import StateContext from 'common/contexts/state-context';

import './MerchantList.css';

const MerchantList = () => {
  const [state] = useContext(StateContext);
  const mapRef = createRef();

  return (
    <section className="merchantListWrapper">
      <article className="merchantListArticleWrapper">
        <div className="merchantListMapComponentWrapper" ref={mapRef}>
          <Map
            mapSize={{
              height: '100%',
              width: '100%',
            }}
            mapConfig={{
              scrollwheel: false,
              styles: MapService.mapStyles.silver
            }}
          />
        </div>
        {state.merchants && (
          <div className="merchantListMerchantComponentContainer">
            <h2>
              <div
                style={{
                  color: 'var(--secondary-color)',
                  fontStyle: 'italic',
                  fontSize: 'var(--fontsize-xxl',
                  fontWeight: 'normal',
                }}
              >
                Résultats pour
              </div>
              <div
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '42px',
                  fontWeight: 'normal',
                  letterSpacing: '2px',
                }}
              >
                {state.merchants.length} SALONS TROUVÉS
              </div>
            </h2>
            <ul className="merchantListMerchantComponentWrapper">
              {state.merchants
                .sort((a, b) => {
                  return a.distance - b.distance;
                })
                .sort((a, b) => {
                  return a.name.toLowerCase() - b.name.toLowerCase();
                })
                .map((merchant, index) => {
                  return <StoreCard merchant={merchant} index={index} />;
                })}
            </ul>
          </div>
        )}
      </article>
    </section>
  );
};

const StoreCard = ({ merchant, index }) => {
  return (
    <li className="merchantCardWrapper">
      <a href={merchant.localpageUrl} style={{ width: '100%' }}>
        <div className="merchantCardInfosWrapper">
          <div className="merchantCardInfosNumberWrapper">
            <div className="merchantCardInfosNumberTag">{index + 1}</div>
          </div>
          <div className="merchantCardInfosText">
            <h2>{merchant.name}</h2>
            <address>
              <p style={{ marginTop: 'var(--gutter-s)', marginBottom: 'var(--gutter-s)' }}>{merchant.address.street}</p>
              <p style={{ marginTop: 'var(--gutter-s)', marginBottom: 'var(--gutter-s)' }}>
                {merchant.address.zip} {merchant.address.city}
              </p>
            </address>
            {merchant.phone && <p>Tél : {merchant.phone}</p>}
            {merchant.extended_data.extended_string_data_4 && (
              <a href={merchant.extended_data.extended_string_data_4} rel="nofollow noopener noreferrer">
                <div className="merchantCardWaitingList">
                  Prendre place dans la file d'attente
                </div>
              </a>
            )}
          </div>
          <div className="merchantCardDistance">
            {merchant.distance.toPrecision(3)} Km
          </div>
        </div>
      </a>
    </li>
  );
};

export default MerchantList;
