import React from 'react';
import IconsMapper from 'common/components/IconsMapper/IconsMapper';
import './FollowUs.css';

const FollowUs = ({ merchant }) => {
  const socialNetworks = [];

  if (merchant.extended_data.extended_string_data_1) {
    socialNetworks.push({
      url: merchant.extended_data.extended_string_data_1,
      icon: 'faFacebook',
    });
  }

  if (merchant.extended_data.extended_string_data_2) {
    socialNetworks.push({
      url: merchant.extended_data.extended_string_data_2,
      icon: 'faInstagram',
    });
  }

  return (
    <>
      {socialNetworks.length > 0 && (
        <div className="followUsWrapper">
          <section className="simpleCardWrapper">
            <div className="simpleCardWrapperContainer">
              <h4 className="simpleCardTitle">
                <p className="followUsTitle">SUIVEZ NOUS</p>
              </h4>
            </div>
          </section>

          <div className="followUsIconsWrapper">
            {socialNetworks.map((network) => {
              return (
                <div className="followUsIcon">
                  <a
                    href={network.url}
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    <IconsMapper icon={network.icon}></IconsMapper>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default FollowUs;
