import React, { createRef, useContext, useEffect, useState } from "react";
import MapService from "common/services/Map";
import MapSearch from "common/components/Map/MapSearch";
import Map from "common/components/Map/Map";
import MapContext from "common/contexts/map-context";
import BasicButton from "common/components/Widgets/BasicButton/BasicButton";
import ApiRequests from "common/services/ApiRequests";
import StateContext from "common/contexts/state-context";
import useWindowSize from "common/customHooks/useWindowSize";
import TrackingService from "common/services/Tracking/Tracking";

import "./HomeSearch.css";

const HomeSearch = (props) => {
  const [state, setState] = useContext(StateContext);
  const [map, setMap] = useContext(MapContext);
  const searchBarRef = createRef();
  const { placeLoc } = state?.sl?.settings;
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const { width } = windowSize;
    const mobile = width <= 500 ? true : false;
    setIsMobile(mobile);

    if (props.withGoTo && searchBarRef.current) {
      searchBarRef.current.scrollIntoView(true);
    }
  }, [windowSize, props, searchBarRef]);

  useEffect(() => {
    TrackingService.pageView('storelocator');
    if (placeLoc) {
      TrackingService.trackSearchedPlace();
    }
  }, [placeLoc]);

  const goToGeolocation = async () => {
    const geoLocInfos = await MapService.goToGeolocation();
    const newState = await ApiRequests.getBrands(geoLocInfos.pagination, state);
    setState(newState);
    setMap({
      ...map,
      placeId: geoLocInfos.placeId,
      placeLoc: {
        ...map.placeLoc,
        lat: geoLocInfos.pagination.from_lat,
        lng: geoLocInfos.pagination.from_lng
      }
    });
  };

  return (
    <div className="homeSearchTotalContainer">
      <Map mapSize={{ height: "0", width: "0" }} />

      <div className="homeSearchContainer">
        <div className="homeSearchHeaderWrapper">
          <div className="homeSearchFAP">NOS SALONS</div>
          <div style={{ marginTop: "20px" }}>
            <div className="homeSearchOurSaloon">Proches</div>
            <div className="homeSearchOurSaloon">de vous !</div>
          </div>
        </div>
      </div>

      <div className="homeSearchSearchBarContainer">
        <div className="homeSearchSearchBarWrapper">
          <h1>
            Un salon Tchip vous attend
            <div className="homeSearchTitleBold">près de chez vous...</div>
          </h1>
          <div className="homeSearchSearchBarTools">
            <div className="homeSearchSearchMapS">
              <MapSearch
                input={{
                  placeholder: "Ville ou code postal",
                  style: {
                    color: "#000",
                    height: "30px",
                    padding: "var(--gutter-m)",
                    background: "var(--background-color)",
                    borderRadius:
                      "var(--borderRadius-m) 0 0 var(--borderRadius-m)",
                  },
                }}
                predictionsSelect={{
                  wrapper: {
                    style: {
                      zIndex: 1,
                    },
                  },
                }}
                button={{
                  iconDisabled: true,
                  value: "Go!",
                  style: {
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    padding: "25px",
                    width: "fit-content",
                    fontSize: "var(--fontsize-m)",
                    borderRadius:
                      "0 var(--borderRadius-m)  var(--borderRadius-m) 0",
                  },
                }}
              />
            </div>
            <p style={{ alignSelf: "center" }}>- ou -</p>
            <BasicButton
              label="Localisez moi"
              icon={"faLocationDot"}
              hoverColor={"white"}
              hover={"var(--primary-color"}
              isBig={isMobile && true}
              style={{
                color: "white",
                height: "30px",
                borderRadius: "var(--borderRadius-m)",
              }}
              onClick={() => goToGeolocation()}
            />
          </div>
        </div>
      </div>
      <div ref={searchBarRef}></div>
    </div>
  );
};

export default HomeSearch;
