import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import TrackingService from 'common/services/Tracking/Tracking';
import StateContext from 'common/contexts/state-context';
import Map from 'common/components/Map/Map';
import RichFieldGroup from 'common/components/RichFieldGroup/RichFieldGroup';
import useWindowSize from 'common/customHooks/useWindowSize';

import './LocalPage.css';
import PlaceInfos from './widgets/PlaceInfos/PlaceInfos';
import OpeningHours from './widgets/OpeningHours/OpeningHours';
import SimpleCard from './widgets/SimpleCard/SimpleCard';
import Services from './widgets/Services/Services';
import NearbyPlacesWrapper from './widgets/NearbyPlacesWrapper/NearbyPlacesWrapper';
import {
  RICHFIELDS_CONFIG_DESKTOP,
  RICHFIELDS_CONFIG_MOBILE,
} from './richfieldsConfig/richfieldsConfig';
import FollowUs from './widgets/FollowUs/FollowUs';
import AppLinks from './widgets/AppLinks/AppLinks';
import JoinUs from './widgets/JoinUs/JoinUs';

const LocalPage = () => {
  const [state] = useContext(StateContext);
  const { storeId } = useParams();
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const { width } = windowSize;
    const mobile = width <= 1200 ? true : false;
    setIsMobile(mobile);
  }, [windowSize]);

  useEffect(() => {
    TrackingService.pageView('localpage', storeId);
  }, [storeId]);

  const merchantDescription =
    state.merchant.description ||
    state.merchant.extended_data.extended_string_data_7;

  return (
    <>
      <Map mapSize={{ height: '0', width: '0' }} />
      {state.lp && (
        <>
          <header className="localPageHeader"></header>
          <div className="localPageWrapper">
            <main className="topInfosWrapper">
              <PlaceInfos
                merchant={state.merchant}
                isMobile={isMobile}
              ></PlaceInfos>

              <div className="desktop-only" style={{ width: '20%' }}>
                <OpeningHours merchant={state.merchant}></OpeningHours>
              </div>
            </main>
            <div className="localPageContent">
              <div className="localPageCenter">
                <div className="mobileInfosOnly">
                  <div className="flexGap">
                    <FollowUs merchant={state.merchant} />
                    <NearbyPlacesWrapper
                      isMobile={isMobile}
                    ></NearbyPlacesWrapper>
                  </div>
                  {state.merchant.extended_data.extended_string_data_4 && (
                    <>
                      <div className="appointmentWrapper">
                        <div className="appointmentWithout">
                          SANS
                          <span className="appointmentBig">RENDEZ-VOUS !</span>
                        </div>
                        <SimpleCard
                          merchant={state.merchant}
                          mergeField={4}
                          color={'var(--secondary-color)'}
                        >
                          <Appointment />
                        </SimpleCard>
                      </div>

                      <AppLinks />
                    </>
                  )}
                  <OpeningHours merchant={state.merchant}></OpeningHours>
                </div>

                {merchantDescription && (
                  <article className="descriptionWrapper">
                    <h2 className="descriptionTitle">
                      VOTRE SALON DE COIFFURE
                    </h2>
                    <p className="descriptionContent">{merchantDescription}</p>
                  </article>
                )}
                {isMobile && (
                  <>
                    <RichFieldGroup
                      groupId={'MAIN_B'}
                      style={RICHFIELDS_CONFIG_MOBILE.MAIN_B}
                    ></RichFieldGroup>
                    <RichFieldGroup
                      groupId={'MAIN_C'}
                      style={RICHFIELDS_CONFIG_MOBILE.MAIN_C}
                    />
                    <RichFieldGroup
                      groupId={'ASIDE_B'}
                      style={RICHFIELDS_CONFIG_MOBILE.ASIDE_B}
                    ></RichFieldGroup>
                    {state.merchant?.extended_data?.extended_string_data_9 && (
                      <div className="joinUsMobileWrapper">
                        <JoinUs merchant={state.merchant}></JoinUs>
                        <RichFieldGroup
                          groupId={'ASIDE_A'}
                          style={RICHFIELDS_CONFIG_MOBILE.ASIDE_A}
                        ></RichFieldGroup>
                      </div>
                    )}
                  </>
                )}

                <RichFieldGroup
                  groupId={'MAIN_B'}
                  style={RICHFIELDS_CONFIG_DESKTOP.MAIN_B}
                  classes={RICHFIELDS_CONFIG_DESKTOP.MAIN_B_CLASSES}
                />

                <RichFieldGroup
                  groupId={'MAIN_C'}
                  style={RICHFIELDS_CONFIG_DESKTOP.MAIN_C}
                  classes={RICHFIELDS_CONFIG_DESKTOP.MAIN_C_CLASSES}
                />
              </div>
              <div className="desktop-only">
                <Aside state={state} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const Aside = ({ state }) => {
  return (
    <aside style={{ maxWidth: '230px' }}>
      {state.merchant.extended_data.extended_string_data_4 && (
        <>
          <div>
            <div className="appointmentWithout">
              SANS <span className="appointmentBig">RENDEZ-VOUS !</span>
            </div>
            <SimpleCard
              merchant={state.merchant}
              mergeField={4}
              color={'var(--secondary-color)'}
            >
              <Appointment />
            </SimpleCard>
          </div>
          <AppLinks />
        </>
      )}
      <RichFieldGroup
        groupId={'ASIDE_B'}
        style={RICHFIELDS_CONFIG_DESKTOP.ASIDE_B}
      ></RichFieldGroup>
      <NearbyPlacesWrapper></NearbyPlacesWrapper>
      <RichFieldGroup
        groupId={'ASIDE_A'}
        style={RICHFIELDS_CONFIG_DESKTOP.ASIDE_A}
      ></RichFieldGroup>
      <Services merchant={state.merchant}></Services>
      <JoinUs merchant={state.merchant}></JoinUs>
      <FollowUs merchant={state.merchant} />
    </aside>
  );
};

const Appointment = () => {
  return <p>Prendre place dans la file d'attente</p>;
};

export default LocalPage;
