import React from 'react';
import HourDisplayer from 'common/components/HourDisplayer/HourDisplayer';
import './OpeningHours.css';

const OpeningHours = ({ merchant }) => {
  return (
    <section className="openingHoursWrapper">
      <HourDisplayer></HourDisplayer>
    </section>
  );
};

export default OpeningHours;
