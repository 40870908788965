import { _ as e } from "../_rollupPluginBabelHelpers-f0c52a37.js";
import { useState as t, useEffect as r } from "react";
var i = function (i) {
  var n = t({
      width: void 0,
      height: void 0
    }),
    o = e(n, 2),
    d = o[0],
    h = o[1];
  return r(function () {
    function e() {
      var e = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      i && i.current && (e.width = i.current.offsetWidth, e.height = i.current.offsetHeight), h(e);
    }
    return window.addEventListener("resize", e), e(), function () {
      return window.removeEventListener("resize", e);
    };
  }, []), d;
};
export { i as default };