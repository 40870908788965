import React from 'react';
import './SimpleCard.css';
import IconsMapper from 'common/components/IconsMapper/IconsMapper';

const SimpleCard = ({ merchant, icon, children, mergeField = 1, color }) => {
  const mergeFieldIndex = 'extended_string_data_' + mergeField;
  const url = merchant.extended_data[mergeFieldIndex];

  return (
    <>
      {url && (
        <section
          className="simpleCardWrapper"
          style={color ? { backgroundColor: color } : {}}
        >
          <div className="simpleCardWrapperContainer">
            {icon && (
              <div className="simpleCardWrapperIcon">
                <IconsMapper icon={icon}></IconsMapper>
              </div>
            )}
            <h4 className="simpleCardTitle">
              <a href={url} target="_blank" rel="nofollow noopener noreferrer">
                {children}
              </a>
            </h4>
          </div>
        </section>
      )}
    </>
  );
};

export default SimpleCard;
