const IMG_SIZE = {
  IMAGE_LIST: {
    width: "264px",
    height: "185px",
  },
  NEWSFEED: {
    width: "200px",
    height: "200px",
  },
  IMAGE_BANNER: {
    width: "var(--aside-width)",
    height: "var(--aside-width)",
  },
  NEWSFEED_ASIDE: {
    width: "100%",
    height: "218px",
  },
};

export const RICHFIELDS_CONFIG_MOBILE = {
  /////////////////////////////////
  //                             //
  //         MAIN_A CONFIG       //
  //                             //
  /////////////////////////////////

  MAIN_A: {
    CUSTOM_HTML: {
      wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        backgroundColor: "var(--secondary-color)",
      },
      content: {
        backgroundColor: "",
        color: "var(--textcolor-alt)",
        width: "90%",
        padding: "var(--gutter-l)",
      },
      title: {
        color: "var(--primary-color)",
        textAlign: "center",
        backgroundColor: "white",
        width: "100%",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //         MAIN_B CONFIG       //
  //                             //
  /////////////////////////////////

  MAIN_B: {
    CUSTOM_HTML: {
      wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "calc( (var(--gutter-xxl) * -1) )",
      },
      title: {
        display: "none",
        backgroundColor: "red",
      },
      content: {
        fontSize: "12px",
        textAlign: "center",
        fontFamily: "Poppins",
        marginTop: "15px",
      },
    },
    IMAGE_LIST: {
      srcSet: "xsmall",
      imgContainer: {
        padding: " 0 var(--gutter-xl)",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "var(--gutter-m)",
        width: "90%",
      },
      img: {
        width: "100%",
      },
      imgWrapper: {
        width: "150px",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
      title: {
        textAlign: "center",
        color: "var(--primary-color)",
        marginBottom: "var(--gutter-xl)",
      },
      wrapper: {
        padding: 0,
        width: "100%",
        alignItems: "center",
      },
    },
    NEWSFEED: {
      srcSet: "xsmall",
      headerTitle: {
        display: "none",
      },
      title: {
        display: "none",
      },
      fullWrapper: {
        justifyContent: "normal",
        gap: "var(--gutter-l)",
      },
      newsWrapper: {
        width: "47%",
        marginBottom: 0,
      },
      imgWrapper: {
        width: "100%",
        height: "180px",
        marginBottom: 0,
      },
      fullContainer: {
        padding: "0 var(--gutter-xl)",
        marginTop: "-50px",
        marginBottom: 0,
        backgroundColor: "inherit",
        width: "90%",
      },
      newsTextWrapper: {
        display: "none",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        MAIN C CONFIG        //
  //                             //
  /////////////////////////////////
  MAIN_C: {
    NEWSFEED: {
      srcSet: "xsmall",
      carouselView: true,
      headerTitle: {
        marginBottom: 0,
        color: "var(--primary-color)",
        textAlign: "center",
      },
      newsWrapper: {
        width: "100%",
        flexDirection: "column",
      },
      title: {
        backgroundColor: "var(--primary-color)",
        color: "white",
        width: "calc(100% - (var(--gutter-m) * 2 ) )",
        padding: "var(--gutter-m)",
      },
      imgWrapper: {
        width: "100%",
        height: "var(--mobile-cube-height)",
        marginBottom: 0,
      },
      fullContainer: {
        width: "90%",
        padding: 0,
        marginBottom: 0,
      },
      paragraph: {
        display: "none",
      },
      buttonWrapper: {
        display: "none",
      },
      fullWrapper: {
        display: "block",
      },
    },
    IMAGE_LIST: {
      srcSet: "xsmall",
      wrapper: {
        display: "none",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        MAIN D CONFIG        //
  //                             //
  /////////////////////////////////
  MAIN_D: {
    IMAGE_LIST: {
      srcSet: "thumbnail",
      imgContainer: {
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
        gap: "var(--gutter-m)",
        margin: 0,
        padding: 0,
      },
      img: {
        width: "100%",
      },
      imgWrapper: {
        width: "80px",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
        border: "5px solid var(--textcolor-alt)",
        backgroundColor: "white",
        zIndex: 1,
      },
      title: {
        display: "none",
      },
      wrapper: {
        padding: 0,
        backgroundColor: "inherit",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //            ASIDE            //
  //                             //
  /////////////////////////////////
  ASIDE_A: {
    IMAGE_BANNER: {
      srcSet: "xsmall",
      wrapper: {
        width: "calc(50% - 10px)",
        height: "calc( var(--mobile-cube-height) - 10px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
        border: "5px solid var(--primary-color)",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        ASIDE B              //
  //                             //
  /////////////////////////////////
  ASIDE_B: {
    NEWSFEED: {
      srcSet: "xsmall",
      headerTitle: {
        display: "none",
      },
      fullContainer: {
        padding: 0,
        width: "90%",
      },
      fullWrapper: {
        width: "100%",
        gap: "20px",
      },
      imgWrapper: {
        width: "50%",
        height: "var(--mobile-cube-height)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        margin: 0,
        boxSizing: "border-box",
        border: "5px solid var(--secondary-color)",
      },
      newsWrapper: {
        width: "100%",
        marginBottom: 0,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        gap: "var(--gutter-l)",
      },
      newsTextWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "var(--secondary-color)",
        width: "50%",
        height: "var(--mobile-cube-height)",
        flexDirection: "column",
      },
      title: { color: "white" },
      desc: {
        color: "white",
        textAlign: "center",
      },
      buttonWrapper: {
        display: "none",
      },
      paragraph: {
        textAlign: "center",
      },
    },
  },
};

export const RICHFIELDS_CONFIG_DESKTOP = {
  /////////////////////////////////
  //                             //
  //         MAIN_A CONFIG       //
  //                             //
  /////////////////////////////////

  MAIN_A: {
    CUSTOM_HTML: {
      content: {
        backgroundColor: "",
        padding: "var(--gutter-xl)",
      },
      title: {
        textAlign: "center",
        color: "var(--primary-color)",
        fontSize: "var(--fontsize-xxl)",
        marginTop: "var(--gutter-l)",
      },
    },
  },

  /////////////////////////////////
  //                             //
  //         MAIN_B CONFIG       //
  //                             //
  /////////////////////////////////
  MAIN_B_CLASSES: {
    IMAGE_LIST: {
      wrapper: "desktop-only",
    },
    CUSTOM_HTML: {
      wrapper: "desktop-only",
    },
  },

  MAIN_B: {
    CUSTOM_HTML: {
      wrapper: {
        alignItems: "center",
        justifyContent: "center",
        marginTop: "calc( (var(--gutter-xxl) * -1) )",
      },
      title: {
        display: "none",
        backgroundColor: "red",
      },
      content: {
        fontSize: "12px",
        textAlign: "center",
        fontFamily: "Poppins",
      },
    },
    IMAGE_LIST: {
      srcSet: "xsmall",
      imgContainer: {
        padding: " 0 var(--gutter-xl)",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "var(--gutter-m)",
      },
      img: {
        width: "100%",
      },
      imgWrapper: {
        width: "188px",
        height: "188px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
      title: {
        textAlign: "center",
        color: "var(--primary-color)",
        marginBottom: "var(--gutter-xl)",
        fontSize: "var(--fontsize-xxl)",
      },
      wrapper: {
        padding: 0,
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        MAIN C CONFIG        //
  //                             //
  /////////////////////////////////

  MAIN_C_CLASSES: {
    IMAGE_LIST: {
      wrapper: "desktop-only",
    },
    NEWSFEED: {
      fullContainer: "desktop-only",
    },
  },

  MAIN_C: {
    NEWSFEED: {
      srcSet: "xsmall",
      headerTitle: {
        textAlign: "center",
        color: "var(--primary-color)",
        marginBottom: "var(--gutter-xl)",
        fontSize: "var(--fontsize-xxl)",
      },
      newsWrapper: {
        width: "32.5%",
      },
      imgWrapper: {
        height: IMG_SIZE.IMAGE_LIST.height,
      },
      fullContainer: {
        padding: "0 var(--gutter-xl)",
        marginBottom: 0,
      },
      buttonWrapper: {
        margin: 0,
      },
      button: {
        hoverColor: "var(--primary-color)",
        style: {
          fontSize: "var(--fontsize-s)",
          backgroundColor: "white",
          marginTop: 0,
          padding: 0,
          color: "black"
        },
      },
    },
    IMAGE_LIST: {
      srcSet: "xsmall",
      imgContainer: {
        padding: "0 var(--gutter-xl)",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "var(--gutter-m)",
      },
      imgWrapper: {
        width: "32.5%",
        height: IMG_SIZE.IMAGE_LIST.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
      title: {
        textAlign: "center",
        color: "var(--primary-color)",
        marginBottom: "var(--gutter-xl)",
        fontSize: "var(--fontsize-xxl)",
      },
      wrapper: {
        padding: 0,
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        MAIN D CONFIG        //
  //                             //
  /////////////////////////////////
  MAIN_D: {
    IMAGE_LIST: {
      srcSet: "thumbnail",
      imgContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "var(--gutter-m)",
        margin: 0,
        padding: 0,
      },
      img: {
        width: "100%",
      },
      imgWrapper: {
        width: "110px",
        height: "110px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
        border: "5px solid var(--textcolor-alt)",
        backgroundColor: "white",
      },
      title: {
        display: "none",
      },
      wrapper: {
        padding: 0,
        backgroundColor: "inherit",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //            ASIDE            //
  //                             //
  /////////////////////////////////
  ASIDE_A: {
    IMAGE_BANNER: {
      srcSet: "xsmall",
      wrapper: {
        width: IMG_SIZE.IMAGE_BANNER.width,
        height: IMG_SIZE.IMAGE_BANNER.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        margin: 0,
        border: "var(--gutter-m) solid var(--primary-color)",
        boxSizing: "border-box",
      },
      img: {
        width: "100%",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        ASIDE B              //
  //                             //
  /////////////////////////////////
  ASIDE_B: {
    NEWSFEED: {
      srcSet: "xsmall",
      headerTitle: {
        display: "none",
      },
      fullContainer: {
        padding: 0,
        backgroundColor: "var(--secondary-color)",
        width: "var(--aside-width)",
        marginBottom: 0,
      },
      fullWrapper: {
        width: "100%",
      },
      imgWrapper: {
        width: IMG_SIZE.NEWSFEED_ASIDE.width,
        height: IMG_SIZE.NEWSFEED_ASIDE.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
      newsWrapper: {
        width: "100%",
        marginBottom: 0,
        alignItems: "center",
        justifyContent: "center",
        padding: "var(--gutter-m)",
      },
      title: { color: "white" },
      desc: {
        color: "white",
        textAlign: "center",
      },
      buttonWrapper: {
        display: "none",
      },
    },
  },
};
