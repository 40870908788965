import React from "react";
import "./JoinUs.css";

const JoinUs = ({ merchant }) => {


    const joinUsMail = merchant.extended_data.extended_string_data_9;

    return <>
        {joinUsMail &&
            <div className="joinUsWrapper">
                <a
                    className="appointmentSmall"
                    href={'mailto:' + joinUsMail}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <p >
                        <span>REJOINDRE</span> L'EQUIPE
                    </p>
                </a>

            </div>}
    </>
};

export default JoinUs;
